import {
  checkIsUserLoggedIn,
  deviseSignIn
} from '@polydice/icook-devise-unify';
import Button from '@polydice/ui/dist/Button';
import IconButton from '@polydice/ui/dist/IconButton';
import { triggerToast } from '@polydice/ui/dist/Toast';
import { FC, useCallback, useEffect } from 'react';
import { getAuthToken } from 'reactjs/utils';
import { triggerMessagingAuth } from '../../../components/firebase';
import { logger } from '../../../components/logger';
import { ARIA_ROLE, FOLLOW_BUTTON, TOAST_MESSAGE } from './constants';
import { useConnectState } from './hooks';
import style from './styles.module.scss';
import classNames from 'classnames';

export type OnTriggerFollow = (payload: {
  username: string;
  nextFollowed: boolean;
}) => void;

interface Props {
  username: string;
  followUrl: string;
  notificationUrl: string;
  followed?: boolean;
  enableNotification?: boolean;
  withoutConnected?: boolean;
  onTriggerFollow?: OnTriggerFollow;
  isLogin?: boolean;
  rounded?: boolean;
  plusIcon?: boolean;
}

const authToken = getAuthToken();

export const FollowUserButtonBase: FC<Props> = ({
  username,
  followUrl,
  notificationUrl,
  followed = false,
  enableNotification = false,
  withoutConnected = false,
  onTriggerFollow,
  isLogin = true,
  rounded = false,
  plusIcon = false
}) => {
  const [isFollowed, setFollowed] = useConnectState(
    'followed',
    followed,
    withoutConnected
  );
  const [isEnableNotification, setEnableNotification] = useConnectState(
    'enableNotification',
    enableNotification,
    withoutConnected
  );
  const [isPending, setPending] = useConnectState(
    'pending',
    false,
    withoutConnected
  );

  const redirectToLoginDetector = useCallback(() => {
    if (checkIsUserLoggedIn()) return;
    const { origin, pathname } = window.location;
    deviseSignIn({
      entry: window.location.pathname.includes('users')
        ? 'icook_user_follow'
        : 'icook_recipe_follow',
      redirect: `${origin}${pathname}`
    });
  }, []);

  const triggerFollow = useCallback(async () => {
    redirectToLoginDetector();
    if (isPending) return;

    setPending(true);

    const response = await fetch(followUrl, {
      method: isFollowed ? 'DELETE' : 'POST',
      headers: {
        'X-XSRF-Token': authToken,
        Accept: 'application/json'
      },
      credentials: 'same-origin'
    });

    if (response.ok) {
      const nextFollowed = !isFollowed;
      setFollowed(nextFollowed);
      if (nextFollowed) triggerMessagingAuth();

      triggerToast({
        text: TOAST_MESSAGE[nextFollowed ? 'FOLLOWED' : 'UNFOLLOW'],
        closable: true
      });

      setEnableNotification(nextFollowed);

      onTriggerFollow?.({ username, nextFollowed });
    }

    setPending(false);
  }, [
    followUrl,
    isFollowed,
    isPending,
    redirectToLoginDetector,
    setEnableNotification,
    setFollowed,
    setPending,
    onTriggerFollow,
    username
  ]);

  const triggerEnableNotification = async () => {
    redirectToLoginDetector();
    if (isPending) return;

    setPending(true);
    const newStatusOfIsEnableNotification = !isEnableNotification;

    const response = await fetch(
      `${notificationUrl}?enable_notification=${newStatusOfIsEnableNotification}`,
      {
        method: 'PUT',
        headers: {
          'X-XSRF-Token': authToken
        }
      }
    );

    if (response.ok) {
      setEnableNotification(newStatusOfIsEnableNotification);

      triggerToast({
        text: TOAST_MESSAGE[
          newStatusOfIsEnableNotification
            ? 'ENABLE_NOTIFICATION'
            : 'DISABLE_NOTIFICATION'
        ],
        closable: true
      });
    }

    setPending(false);
  };

  useEffect(() => {
    const fromAmp =
      window.location.hash === '#__icook_amp_continue_follow_user';

    if (!checkIsUserLoggedIn() && fromAmp) {
      window.history.replaceState({}, '', window.location.href.split('#')[0]);
      triggerFollow();
    }
  }, [triggerFollow]);

  return (
    <section className={style.followUserButtonWrapper}>
      {isLogin && (
        <IconButton
          color="gray"
          iconOnClass="bell-regular"
          iconOffClass="bell-slash-regular"
          className={style.followUserButtonNotification}
          disabled={isPending}
          aria-hidden={!isFollowed}
          pressed={isEnableNotification}
          aria-label={ARIA_ROLE.NOTIFICATION_BUTTON}
          onClick={triggerEnableNotification}
        />
      )}

      <Button
        className={classNames(style.followUserButton, {
          [style.rounded]: rounded
        })}
        aria-label={ARIA_ROLE.FOLLOW_BUTTON}
        disabled={isPending}
        styleType="contained"
        size="xs"
        color={isFollowed ? 'gray' : 'blue'}
        onClick={triggerFollow}
      >
        {`${plusIcon ? '+ ' : ''}${
          FOLLOW_BUTTON[isFollowed ? 'FOLLOWED' : 'FOLLOW']
        }`}
      </Button>
    </section>
  );
};

export const FollowUserButton: FC<Omit<Props, 'onTriggerFollow'>> = (props) => {
  const onTriggerFollow: OnTriggerFollow = useCallback((payload) => {
    logger.logEvent('c2_author_follow', {
      username: payload.username,
      activated: payload.nextFollowed
    });
  }, []);
  return <FollowUserButtonBase {...props} onTriggerFollow={onTriggerFollow} />;
};
