/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from 'react';
import { useChannelState } from 'reactjs/utils/channel';

export const useConnectState = (
  type: string,
  initialState: boolean,
  withoutConnected: boolean
) => {
  const connectedState = withoutConnected
    ? useState(initialState)
    : useChannelState(type, initialState);

  return connectedState;
};
