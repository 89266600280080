import { useState, useEffect } from 'react';
import event from 'events';

const emitter = new event.EventEmitter();

const useChannelState = (channelType: string, initialState: any = null) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    emitter.on(channelType, setState);
    return () => {
      emitter.off(channelType, setState);
    };
  }, [channelType]);

  const setChannelState = (newState: any) => {
    emitter.emit(channelType, newState);
  };

  return [state, setChannelState];
};

export { useChannelState };
