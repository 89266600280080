export const ARIA_ROLE = {
  FOLLOW_BUTTON: '追蹤按鈕',
  NOTIFICATION_BUTTON: '通知小鈴鐺'
};

export const FOLLOW_BUTTON = {
  FOLLOW: '追蹤',
  FOLLOWED: '已追蹤'
};

export const TOAST_MESSAGE = {
  FOLLOWED: '已追蹤，並接收最新食譜通知',
  UNFOLLOW: '已取消追蹤',
  ENABLE_NOTIFICATION: '已開啟通知',
  DISABLE_NOTIFICATION: '已關閉通知'
};
